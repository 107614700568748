<style lang="less" scoped>
  .revision{
    padding-top: 80px;
  }
</style>

<template>
  <div class="revision">
    <div class="t_mess_box " v-animate="'t_mess_box_cover'" v-if="shoptypeList && shoptypeList.length" @mouseleave="changeHandle({})">
      <div class="p_type_box" style="animation: leftright 1s 0s linear both">
        <div class="type" v-for="(item, key) in shoptypeList" :key="item.id + '&' + key"  @mousemove="changeHandle(item)">
          <p class="o_type" :class="{noChild: !item.children.length}" @click="
            tolist(
              item.id,
              item.code,
              item.name
            )
          ">{{ item.name }}</p>
          <div class="child_type" v-if="item.children.length">
            <p
              :class="{mall: item.children.length>1}"
              v-for="(i,j) in item.children"
              v-show="j<2"
              :key="i.code + 'child' + j"
              @click="gochildtype(item.id,i.code,item.code,item.name)"
            >
              {{ i.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="allType" v-if="handleItem.children && handleItem.children.length > 1">
        <img src="@/assets/image/RevisionHome/allType.png" alt="">
        <div class="t">
          <p class="title" @click="
            tolist(
              handleItem.id,
              handleItem.code,
              handleItem.name
            )
          ">{{ handleItem.name }}</p>
          <div class="all_type">
            <p
              v-for="(i,j) in handleItem.children"
              :key="j + '&&' + handleItem.id"
              @click="gochildtype(handleItem.id,i.code,handleItem.code,handleItem.name)"
            >
              {{ i.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="banber_box banner-box swiper-container">
        <swiper ref="mySwiperBanner" class="swiper-wrapper" :options="bannerOption">
          <swiper-slide
            class="swiper-slide"
            v-for="(item, key) in bannerList"
            :key="item.id + '$' + key"
          >
            <img @click.stop="onCarouselHand(key)" class="banner-img" :src="item.bannerLink" alt="首页轮播图" />
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
      <div class="u_mess_box">
        <div class="user" :class="{studying: $store.state.userInfo.isLogin}">
          <template v-if="!$store.state.userInfo.isLogin">
            <img src="@/assets/image/RevisionHome/noLoginAuth.png" alt="">
            <p>未登录</p>
          </template>

          <template v-else>
            <img src="@/assets/image/RevisionHome/learningIcon.png" alt="">
            <p class="mallHidden">在学课程</p>
          </template>
        </div>
        <template v-if="!$store.state.userInfo.isLogin">
          <img class="noLogin" src="@/assets/image/RevisionHome/noLogin.png" alt="">
          <div class="btn all-btn-small" @click="$router.push('/login/loginIndex?type=2')">去登录</div>
        </template>
        <template v-else>
          <img class="noLearning" v-if="!learningList.length" src="@/assets/image/RevisionHome/noLearning.png" alt="">
          <div v-else @click="goStudy(item)" class="learning onlyOneHidden" v-for="(item,i) in learningList" :key="item.courseId + 'id-B' + i">{{ item.courseName }}</div>
          <div class="btn all-btn-small" @click="$router.push('/study/study')">去学习</div>
        </template>
      </div>
    </div>
    
    <div class="heavyCourse module-inner"  v-animate="'topbpttom'" v-if="HeavyProductList && HeavyProductList.length">
      <div v-for="(list,index) in HeavyProductList"
          :key="list.id">
        <div class="title">
        <img src="@/assets/image/revision/heavyIcon.png" alt="" class="heavyIcon">
        <p>{{ list.title }}</p>
        <!-- <div class="mall"
          @click="$router.push('/courseListPage?id='+$AES.encode_data('2')+'&name=重磅课程')">
          更多
          <a-icon class="aIcon" type="right" />
        </div> -->
      </div>
      <div class="banber_box banner-box">
        <swiper ref="mySwiper" class="selfSwiper swiper-wrapper swiper-container" :options="list['HeavybannerOption'+index]">
          <swiper-slide
            class="swiper-slide selfStyle"
            v-for="item in list.productColumnRelations"
            :key="item.id "
            @click.native="toproDetail(item)"
          >
            <img class="banner-img" :src="item.homePage" :onerror="GLOBAL.errorimg()" alt="课程封面" />
            <div class="p_messDetail">
              <p class="name onlyOneHidden">{{ item.productName }}</p>
              <p class="tyle">{{ item.categoryName }} | 视频数量: {{ item.classHour }}个</p>
              <p class="detail mallHidden">{{ item.overview }}</p>
              <div class="p_btn">
                <p class="price"> {{ item.couponPrice }}</p>
                <p class="button all-btn-small">立即购买</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="btn_back" v-if="list.productColumnRelations.length > 2">
          <div class="swiper-button-next" :class="'swiper-button-next'+index"></div>
          <div class="swiper-button-prev" :class="'swiper-button-prev'+index"></div>
          <div class="Heavyswiper-pagination" :class="'Heavyswiper-pagination'+index"></div>
        </div>
      </div>
      </div>
      
    </div>

    <!-- <div class="studyMap">
      <div class="title">
        <img src="@/assets/image/RevisionHome/studyMap.png" alt="" class="heavyIcon">
        <p>学习地图</p>
      </div>
      <div class="banber_box banner-box">
        <swiper ref="mySwiper" class="swiper-wrapper swiper-container" :options="studyMapbannerOption">
          <swiper-slide
            class="swiper-slide"
            v-for="item in studyMapList"
            :key="item.id"
          >
            <div class="RecommendedReason">
              <img src="@/assets/image/RevisionHome/RecommendedReason.png" alt="">
              <p class="reasonTitle">推荐理由</p>
              <p class="reasonText">{{ item.detail }}</p>
            </div>
            <div class="productList">
              <div class="product" v-for="(i, j) in item.productColumnRelations" :key="i.id">
                <div class="mess">
                  <img class="productimg" :src="i.homePage" alt="">
                  <p class="name mallHidden">{{ i.productName }}</p>
                  <p class="tyle">{{ i.categoryName }} | 视频数量: {{ i.classHour }}个</p>
                </div>
                <div class="operate" @click="toproDetail(i)">
                  <img src="@/assets/image/RevisionHome/shopCarIcon.png" alt="">
                  <img class="whiteshopCarIcon" src="@/assets/image/RevisionHome/whiteshopCarIcon.png" alt="">
                  <p class="index">第{{ num[j] }}课</p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="btn_back" v-if="studyMapList.length > 2">
          <div class="studyMapswiper-button-prev"><a-icon class="aIcon" type="left" /></div>
          <div class="studyMapswiper-pagination"></div>
          <div class="studyMapswiper-button-next"><a-icon class="aIcon" type="right" /></div>
          </div>
      </div>
    </div> -->

    <div class="columnCourse module-inner" v-animate="'topbpttom'" v-if="b_productList && b_productList.length">
      <div class="banber_box banner-box">
        <swiper ref="mySwiper" class="swiper-wrapper swiper-container" :options="b_productListOption">
          <swiper-slide
            class="swiper-slide"
            v-for="item in b_productList"
            :key="item.id"
          >
            <div class="title"><img src="@/assets/image/RevisionHome/columnIcon.png" class="columnIcon" />{{ item.title }}</div>
            <div class="list">
              <div class="product" @click="toproDetail(ite)" v-show="i<4" v-for="(ite,i) in item.productColumnRelations" :key="i">
                <img class="banner-img" :src="ite.listPhoto" :onerror="GLOBAL.errorimg()" alt="课程封面" />
                <img class="index" :src="require('@/assets/image/RevisionHome/indexIcon_'+(i+1)+'.png')" alt="" />
                <div class="detail">
                  <p class="name mallHidden">{{ ite.productName }}</p>
                  <p class="tyle">{{ ite.categoryName }} | 视频数量: {{ ite.classHour }}个</p>
                </div>
              </div>
            </div>
            <div class="btn" @click="$router.push('/courseListPage?id='+$AES.encode_data(item.id+'')+'&name='+item.title)">查看所有<a-icon class="aIcon" type="right" /></div>
          </swiper-slide>
        </swiper>
        <template v-if="b_productList.length > 2">
          <div class="column_swiper-button-next"><a-icon type="left" /></div>
          <div class="column_swiper-button-prev"><a-icon type="right" /></div>
          <div class="columnswiper-pagination"></div>
        </template>
      </div>
    </div>
    
    <div class="heavyCourse module-inner" v-animate="'topbpttom'" v-if="freeProductList && freeProductList.length">
      <div class="title">
        <img style="width: 22px;height: 22px;" src="@/assets/image/RevisionHome/freeIcon.png" alt="" class="heavyIcon">
        <p>免费课程</p>
        <div class="mall"
          @click="$router.push('/courseListPage?id='+$AES.encode_data('4')+'&name=免费课程')">
          更多
          <a-icon class="aIcon" type="right" />
        </div>
      </div>

      <div class="free_product">
        <div class="product" @click="toproDetail(item)" v-for="(item, index) in freeProductList" :key="index">
          <!-- <div class="indexbox">
            <div class="index">0{{ index+1 }}</div>
            <a-icon class="aIcon" type="arrow-right" />
          </div> -->
          <p class="name mallHidden">{{ item.productName }}</p>
          <p class="tyle">{{ item.categoryName }} | 视频数量: {{ item.classHour }}个</p>
          <!-- :src="item.homePage" -->
          <img class="banner-img" v-lazy="item.homePage" :key="item.homePage" :onerror="GLOBAL.errorimg()" alt="课程封面" />
        </div>
      </div>
    </div>

    <!-- <div class="GoodsBooks">
      <div class="title">
        <img src="@/assets/image/revision/goodsBooks.png" alt="" class="heavyIcon">
        <p>华夏图书</p>
      </div>
      <div class="box_Back">
        <div class="bookBanner banber_box banner-box swiper">
          <swiper ref="BOOKmySwiper" class="swiper-wrapper swiper-container" :options="Books_productListOption">
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in Books_productList"
              :key="item.id"
            >
              <div class="typeBox" :class="{cccBox:index%2 != 0}">
                <img class="banner-img" v-if="index%2 == 0" src="@/assets/image/RevisionHome/bookIcon_1.png" alt="" />
                <img class="banner-img" v-else src="@/assets/image/RevisionHome/bookIcon_2.png" alt="" />
                <p class="typeText">{{ item.name }}</p>
                <p class="btn"
                  :class="{typecolor:index%2 == 0}"
                  @click="gochildtype(item.id,item.code,'05',item.name)"
                >
                  查看所有<a-icon class="aIcon" type="right" />
                </p>
              </div>
              <div class="contentBox" :class="{cccBox:index%2 != 0}">
                <div class="pro_box" @click="toproDetail(i)" v-for="i in item.children" :key="i.productId">
                  <img class="banner-img" :src="i.pcPhoto" :onerror="GLOBAL.errorimg()" alt="" />
                  <div class="r_mess">
                    <p class="name mallHidden">{{ i.name }}</p>
                    <div class="priceOption">
                      <p class="price">{{ i.couponPrice }}</p>
                      <img class="joinCar" @click="joinShopCar($event,i)" src="@/assets/image/RevisionHome/shopCarIcon.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="Books_swiper-button-next"><a-icon type="left" /></div>
          <div class="Books_swiper-button-prev"><a-icon type="right" /></div>
        </div>

        <div class="title">
          <img src="@/assets/image/revision/training.png" alt="" class="heavyIcon">
          <p>精品教具</p>
        </div>

        <div class="handle">
          <div class="picture">
            <img class="banner-img" :src="training_Handle.pcPhoto" :onerror="GLOBAL.errorimg()" alt="">
            <div class="colorBox"><img src="@/assets/image/RevisionHome/training.png" alt=""></div>
          </div>
          <div class="handleMess">
            <div class="mess">
              <p class="name">{{ training_Handle.name }}</p>
              <p class="btn1" @click="toproDetail(training_Handle)">立即购买</p>
              <p class="btn2" @click="tolist('10', '06', '精品教具')">查看所有<a-icon class="aIcon" type="right" /></p>
            </div>
            <p class="price">{{ training_Handle.couponPrice }}</p>
            <div class="trainingBanner banber_box banner-box">
              <swiper ref="trainingmySwiper" class="swiper-wrapper swiper-container" :options="training_productListOption">
                <swiper-slide
                  class="swiper-slide"
                  v-for="(item, index) in training_productList"
                  :key="item.id"
                  @click.native="settraining_Handle(item)"
                >
                  <div class="typeBox" :class="{cccBox:index%2 != 0}">
                    <img class="banner-img" :src="item.pcPhoto" alt="" />
                    <p class="name mallHidden" :title="item.name">{{ item.name }}</p>
                    <div class="priceOption">
                      <p class="price">{{ item.couponPrice }}</p>
                      <img class="joinCar" @click="joinShopCar($event,item)" src="@/assets/image/RevisionHome/shopCarIcon.png" alt="">
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="training_swiper-button-next"><a-icon type="left" /></div>
              <div class="training_swiper-button-prev"><a-icon type="right" /></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    
    <!-- 孤独症之声 -->
    <div class="autism-item">
      <div class="margin-area module-inner"  v-animate="'topbpttom'" v-if="voiceList && voiceList.length">
        <!-- <img src="@/assets/image/publicBenefit/icon_02.png" alt=""> -->
        <h4 class="public-h4">孤独症之声</h4>
        <div class="content">
          <div class="side-left">
            <span class="title">孤独症之声（Autism Speaks）</span>
            <div class="detail" v-html="introduce"></div>
          </div>
          <div class="swiper-right">
            <div class="tabSwiper">
              <template>
                <swiper
                  :options="gdzswiperOptiont"
                >
                  <swiper-slide
                    v-for="item in voiceList" :key="item.autismId"
                    class="swiper-item"
                  >
                    <div class="swiper-inner" @click="toGdz(item)">
                      <!-- :src="item.img" -->
                      <img class="cover" v-lazy="item.img" :key="item.img" alt="电子书封面">
                      <p class="title">{{item.name}}</p>
                    </div>
                  </swiper-slide>
                </swiper>
              </template>

              <!-- 前进后退按钮 -->
              <div
                class="voice-prev"
                slot="button-prev"
                @mouseover="changeMaskv(1)"
                @mouseout="changeMaskv(2)"
              >
                <img
                  class="proright"
                  v-show="swiperLeftv"
                  src="@/assets/image/knowledgeMall/circularleft2.png"
                  alt=""
                />
                <img
                  class="proright"
                  v-show="!swiperLeftv"
                  src="@/assets/image/knowledgeMall/circularleft.png"
                  alt=""
                />
              </div>
              <div
                class="voice-next"
                slot="button-next"
                @mouseover="changeMaskv(3)"
                @mouseout="changeMaskv(4)"
              >
                <img
                  class="proleft"
                  v-show="swiperRightv"
                  src="@/assets/image/knowledgeMall/circularright2.png"
                  alt=""
                />
                <img
                  class="proleft"
                  v-show="!swiperRightv"
                  src="@/assets/image/knowledgeMall/circularright.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>

      <div class="else_content module-inner"  v-animate="'topbpttom'" v-if="SPVideoList && SPVideoList.length">
        <div class="kpVideo">
          <div class="title">
            <img style="width: 20px;height: 21px;" src="@/assets/image/RevisionHome/kpVideo.png" alt="" class="heavyIcon">
            <p>科普视频</p>
            <div class="mall"
              @click="$router.push('/publicBenefit?type=keVideo')">
              更多
              <a-icon class="aIcon" type="right" />
            </div>
          </div>
          <div class="video-list">
            <div class="side-item" v-for="item in SPVideoList" :key="item.id" @click="toVideoPage(item.id)">
              <!-- :src="item.banner" -->
              <img class="cover" v-lazy="item.banner" :key="item.banner" alt="科普视频封面">
              <div class="info">
                <div class="head">
                  <p class="onlyOneHidden">{{item.articleTitle}}</p>
                  <div class="label">
                    <span v-for="(label,index) in fun_split(item.label)" :key="index">#{{label}}</span>
                  </div>
                </div>
                <div class="detail" v-html="item.overview"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="bwbdBox">
          <div class="title">
            <img style="width: 21px;height: 21px;" src="@/assets/image/RevisionHome/bwbdIcon.png" alt="" class="heavyIcon">
            <p>百问百答</p>
            <div class="mall"
              @click="$router.push('/publicBenefit?type=bwbdBox')">
              更多
              <a-icon class="aIcon" type="right" />
            </div>
          </div>

          <div class="infoList">
            <div class="info" @click="goArticleDetail(item)" v-for="item in bwbdInfolist" :key="item.id">
              <p class="name">{{ item.articleTitle }}</p>
              <p class="overview mallHidden">{{ item.overview }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="whiteBox"  v-animate="'topbpttom'" v-if="BooksproductList && BooksproductList.length">
      <div></div>
      <div class="heavyCourse module-inner">
        <div class="title">
          <img src="@/assets/image/RevisionHome/bookIcon.png" alt="" class="heavyIcon">
          <p>图书教具</p>
          <div class="mall"
            @click="gochildtype('0','','05','图书教具')">
            更多
            <a-icon class="aIcon" type="right" />
          </div>
        </div>

        <div class="book_else">
          <div class="box">
            <div class="boxTitle">
              <img src="@/assets/image/RevisionHome/bookIcon_3.png" alt="">
              <p>精品图书</p>
            </div>

            <div class="shops">
              <div class="product" @click="toproDetail(item)" v-show="index < 6" v-for="(item,index) in BooksproductList" :key="index + 'book'">
                <!-- :src="item.pcPhoto" -->
                <img v-lazy="item.pcPhoto" :key="item.pcPhoto" alt="图书封面">
                <div class="mess">
                  <p class="name mallHidden">{{ item.name }}</p>
                  <p class="price">{{ item.couponPrice }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="boxTitle">
              <img src="@/assets/image/RevisionHome/jjIcon.png" alt="">
              <p>精品教具</p>
            </div>

            <div class="shops">
              <div class="product" @click="toproDetail(item)" v-show="index < 6" v-for="(item,index) in trainingProductList" :key="index">
                <!-- :src="item.pcPhoto" -->
                <img v-lazy="item.pcPhoto" :key="item.pcPhoto" alt="">
                <div class="mess">
                  <p class="name mallHidden">{{ item.name }}</p>
                  <p class="price">{{ item.couponPrice }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="information module-inner"  v-animate="'topbpttom'" v-if="infotabList && infotabList.length">
      <div class="title">
        <img src="@/assets/image/revision/training.png" alt="" class="heavyIcon">
        <p>资讯速递</p>
      </div>
      <a-tabs v-model="infoTypeId">
        <p @click="$router.push('/info')" class="btn2" slot="tabBarExtraContent">
          查看所有<a-icon class="aIcon" type="right" />
        </p>
        <a-tab-pane :tab="item.columnTitle" v-for="item in infotabList" :key="item.columnCode">
          <div class="news" v-for="(ite, index) in infoList" :key="ite.newsId" @click="toNewsDetail(ite)">
            <img v-if="index == 0" src="@/assets/image/RevisionHome/infologo.png" alt="">
            <div class="content">
              <div class="index">0{{ index+1 }}</div>
              <div class="mess">
                <div class="name mallHidden">{{ ite.articleTitle }}</div>
                <div class="summary mallHidden">{{ ite.overview }}</div>
                <div class="option">
                  <p>发布：{{ ite.publishTime }}</p>
                  <p>浏览量：{{ ite.pageviews }}</p>
                  <p class="onlyOneHidden" :title="ite.source">来源：{{ ite.source }}</p>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- 活动告知弹窗 -->
    <a-modal
      v-model="isActiveVisible"
      width="600px"
      class="active-modal sutdent-active"
    >
      <div class="modal-content">
        <img class="bg" :src="bannerStudentActiveImg" alt="">
        <img class="gif" src="@/assets/image/index/hand.gif" alt="">
      </div>
      <template #footer></template>
    </a-modal>
    <Sidebar ref="sidebar"/>
  </div>
</template>

<script>
import {userTrack} from '@/unit/userTrack.js';
import Sidebar from "@/components/shopMallSidebar.vue";
import joinShop from './mixins/joinShop.vue' // 加购方法
export default {
  mixins: [joinShop],
  // 可用组件的哈希表
  components: { Sidebar },
  data() {
    return {
      handleItem: {},
      // 轮播
      bannerStudentActiveImg: require('@/assets/image/index/20240704-105626.png'),
      bannerTeacherActiveImg: require('@/assets/image/index/20240826-100359.jpg'),
      bannerActiveType:'',
      bannerList: [], // 轮播列表
      swiperIndex: 0,
      bannerOption: {
        slidesPerView:"auto",
        //显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
        },
        centeredSlides : true,
        //自动轮播
        autoplay: {
          delay: 8000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        //开启循环模式
        loop: false,
        effect:'fade',
        on: {
          slideChange: () => {
            this.getSwiperIndex();
          },
        },
      },

      freeProductList: [], // 免费商品

      HeavyProductList: [], // 重磅课程商品列表
      HeavybannerOption: {
        // loop: true, // 循环模式选项
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 30,
        initialSlide: 0,
        pagination: {
          el: ".Heavyswiper-pagination",
          type: 'fraction'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },

      b_productList: [], // 轮播商品分类列表
      b_productListOption: {
        // loop: true, // 循环模式选项
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 30,
        initialSlide: 0,
        pagination: {
          el: ".columnswiper-pagination",
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.column_swiper-button-next',
          prevEl: '.column_swiper-button-prev',
        },
      },

      BooksproductList: [], //改版图书商品列表
      trainingProductList: [], // 改版教具列表

      Books_productList: [], // 图书商品列表
      Books_productListOption: {
        // loop: true, // 循环模式选项
        autoplay: false,
        slidesPerView: 3,
        spaceBetween: 28,
        initialSlide: 0,
        pagination: {
          el: "",
          type: 'bullets'
        },
        navigation: {
          nextEl: '.Books_swiper-button-next',
          prevEl: '.Books_swiper-button-prev',
        },
      },

      training_productList: [], // 教具列表
      training_Handle: {}, // 当前选中教具
      training_productListOption: {
        // loop: true, // 循环模式选项
        autoplay: false,
        slidesPerView: 5,
        spaceBetween: 28,
        initialSlide: 5,
        pagination: {
          el: "",
          type: 'bullets'
        },
        navigation: {
          nextEl: '.training_swiper-button-next',
          prevEl: '.training_swiper-button-prev',
        }
      },

      infotabList: [], // 资讯分类列表
      infoTypeId: '',
      infoList: [], // 资讯列表

      shoptypeList: [], // 商品类型

      learningList: [], // 在学课程列表

      studyMapList: [], // 学习地图列表
      studyMapbannerOption: {
        // loop: true, // 循环模式选项
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 30,
        initialSlide: 0,
        pagination: {
          el: ".studyMapswiper-pagination",
          type: 'fraction'
        },
        navigation: {
          nextEl: '.studyMapswiper-button-next',
          prevEl: '.studyMapswiper-button-prev',
        },
      },
      num: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],

      // 孤独症之声      
      introduce: null,
      voiceList: [],
      vtypeID:0,
      gdzswiperOptiont: {
        slidesPerView: "auto",
        spaceBetween: 18,
        centeredSlides: false,
        centeredSlidesBounds: false,
        navigation: {
          nextEl: ".voice-next",
          prevEl: ".voice-prev",
          disabledClass: "my-button-disabled",
        },
      },
      swiperLeftv: false,
      swiperRightv: false,
      isActiveVisible: false,

      SPVideoList:[], // 科普视频
      SPVideoTotal: 0,

      bwbdInfolist: [], // 百问百答资讯列表
    }
  },
  created() {
    this.getShopTypeList();
    this.getBannerList();
    this.getHeavyProductList();
    this.getHeavyProductSlideList();
    this.getBooksColumns();
    this.getInfotab();
    this.getStudyMap();
    this.getVoicetab()
    this.getClient('SPVideo', 6)
    this.getInfoList();
    if(this.$store.state.userInfo.isLogin) {
      this.getLearningList();
    }
  },
  methods: {
    changeHandle(e) {
      if(!e.code) {
        if(!this.handleItem.code) {
          return
        }
      }
      if(e.code == this.handleItem.code) {
        return
      }
      this.handleItem = e
    },
    // 格式化标签
    fun_split(str){
      let arr = []
      if(!str){return arr}
      arr = str.split("，")
      return arr
    },
    
    goArticleDetail(e) {
      this.$router.push(
        "/ArticleDetail?id=" +
          this.$AES.encode_data(String(e.id))
      );
    },
    // 去学习
    goStudy(e) {
      if (e.validStatus == 2) {
        this.$message.warning("请前往学习中心-在学课程中申请延期");
        return;
      }
      if (!e.hasVideoFlag && !e.hasSectionFlag) {// 没有章节、没有视频，提示文字
        // return this.$message.warning("课程暂未更新，请耐心等待开课")
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
        return
      }
      if (e.isOpenClass == 1) {
        let arr = e.startTime.split("-");
        this.$message.warning(
          "课程学习尚未开始，开始时间为" +
            arr[0] +
            "年" +
            arr[1] +
            "月" +
            arr[2] +
            "日"
        );
      } else {
        // 课程是否顺序播放  watchByCatalogueOrder 1.按顺序播放  0.不按顺序播放
        if(e.watchByCatalogueOrder == 1){
          this.$ajax({
            url: "/hxclass-pc/course/go-study",
            params: {
              courseId: e.courseId,
            },
          }).then((res) => {
            if (res.code == "200" && res.success) {
              this.$router.push(
                "/curriculum/course?courseId=" +
                this.$AES.encode_data(String(e.courseId)) +
                "&isBuy=" +
                "&isHave=1"
              );
            }else{
              if(res.code == -2){
                let str = res.message + "若已通过其他平台获得资质，请前往app提交资质进行学习。";
                res.message = str;
              }
              this.$message.warning(res.message);
            }
          });
        } else {// 跳转不需要按顺序播放的课程详情页
          this.$ajax({
            url: "/hxclass-pc/course/getUserCurrentSection",
            params: {
              courseId: e.courseId,
            },
          }).then((res) => {
            if (res.code == "200" && res.success) {
              sessionStorage.removeItem("NextVod");

              this.$router.push(
                "/curriculum/courseRandom?courseId=" +
                this.$AES.encode_data(String(e.courseId)) +
                "&isHave=1"
              );
            }else{
              this.$message.warning(res.message);
            }
          });
        }
      }
    },
    toproDetail(item) {
      if (item.productType == 1 || item.type == 1) {
        this.$router.push({
          path: "/knowledgeMall/courseDetail",
          query: {
            productId: this.$AES.encode_data(item.productId + ""),
            code: item.code ? item.code : item.productCode,
          },
        });
      } else if (
        item.productType == 2 ||
        item.productType == 3 ||
        item.type == 2 ||
        item.type == 3
      ) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      } else if (item.productType == 4 || item.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      }
    },
    toNewsDetail(item) {
      this.$router.push(
        "/infoDetail?newsId=" + this.$AES.encode_data(String(item.id)) + 
        '&banner=1'
      );
    },
    tolist(w, e, r) {
      this.$router.push(
        "/knowledgeMall/educationalCurriculum?id=" +
          this.$AES.encode_data(w + "") +
          "&code=" +
          e +
          "&name=" +
          r
      );
    },
    toVideoPage(id){
      // if(!this.$store.state.userInfo.userId){
			// 	return this.$router.push('/login/loginIndex?type=2');
			// }
      this.$router.push({
        path: "/videoDetail",
        query: {
          id: this.$AES.encode_data(id + ""),
          type: '科普视频',
          from: '首页'
        },
      });
    },  
    // 跳转子商品分类
    gochildtype(id,childcode,code,name) {
      this.$router.push(
        '/knowledgeMall/educationalCurriculum?id=' +
          this.$AES.encode_data(id + '') +
          '&tabCode=' +
          childcode +
          '&code=' +
          code +
          '&name=' +
          name
      )
    },
    settraining_Handle(e) {
      this.$set(this,'training_Handle',e);
    },
    // 获取轮播图列表
    getBannerList() {
      this.$ajax({
        url: '/hxclass-pc/Banner/client/list',
        params: {
          locationCode: 'AppHomePageBanner'
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.bannerList = res.data;
        }
      })
    },
    getSwiperIndex() {
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        this.swiperIndex = this.$refs.mySwiperBanner.$swiper.realIndex;
      }, 100);
    },
    onCarouselHand(index) {
      let obj = this.bannerList[index]
      
      // 记录用户轨迹
      let params = {
        actionDetail: obj.id, //	访问资源id（如对应的banner、商品和文章id，路径触发先设置首页为1）
        actionType: 1, //	触发分类：1、banner触发(轮播图、弹窗)、2 路径触发(启动访问首页)、3商品详情、4 文章详情
        os: 3, //	1、ios2、安卓 3、PC 4、H5
        userId: this.$store.state.userInfo.userId
      }
      this.saveUserTrack(params);

      if(obj.jumpType){
        if(obj.jumpType == 1){
          // 大学优惠弹窗展示
          let isStudent = obj.bannerTitle.includes('大学生专属优惠')
          let isTeacher = obj.bannerTitle.includes('教师节活动')
          
          if(obj.jumpType == "1" && isStudent){
            this.isActiveVisible = true
            return
          } else if (obj.jumpType == "1" && isTeacher){ // 教师节活动
            this.$router.push('/activity/teacherActive')
            return
          }
          // 外部链接
          window.open(obj.jumpLink);
        } else if (obj.jumpType == 2){
          // 资讯
          this.$router.push('/infoDetail?newsId=' + this.$AES.encode_data(String(obj.jumpLink)) + '&banner=true')
        } else if (obj.jumpType == 3){
          this.$ajax({
            url: '/hxclass-pc/course/getProductIdByCourseId',
            params: {
              courseId: obj.jumpLink
            }
          }).then(res=>{
            if(res.code == 200 && res.success) {
              // 课程
              this.$router.push('/knowledgeMall/courseDetail?productId=' + this.$AES.encode_data(String(res.data)))
            }
          })
        } else if (obj.jumpType == 7){

        }
      }
      // return
      // let link = this.bannerList[this.swiperIndex].link;
      // if (link) {
      //   let reg =
      //     /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      //   if (reg.test(link)) {
      //     // 外部链接
      //     window.open(link);
      //   } else {
      //     // 内部链接
      //     this.$router.push(link);
      //   }
      // }
    },

    // 获取首页-督导展示内容
    getHeavyProductList() {
      this.$ajax({
        url: '/hxclass-pc/ProductColumnRelation/client/columnAndProduct',
        params: {
          columnCode: 'AppHomeCourse',
          pageNum: 1,
          pageSize: 6
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          // res.data.map((item,index) => {
          //   if(item.columnCode == 'Heavy') {
          //     // 存储重磅课程列表
          //     this.HeavyProductList = res.data.splice(index,1)[0].productColumnRelations;
          //   }else if(item.columnCode == 'Free') {
          //     // 存储免费商品列表
          //     this.freeProductList = res.data.splice(index,1)[0].productColumnRelations;
          //   }
          // })
          res.data.map((item,index)=>{
            item["HeavybannerOption"+index] = {
              // loop: true, // 循环模式选项
              autoplay: false,
              slidesPerView: 2,
              spaceBetween: 30,
              initialSlide: 0,
              pagination: {
                el: ".Heavyswiper-pagination"+index,
                type: 'fraction'
              },
              navigation: {
                nextEl: '.swiper-button-next'+index,
                prevEl: '.swiper-button-prev'+index,
              },
            }
          })
          // 存储原数组splice剩下的数据
          this.HeavyProductList = res.data;
        }
      })
    },
    // 获取首页-课程展示内容
    getHeavyProductSlideList() {
      this.$ajax({
        url: '/hxclass-pc/ProductColumnRelation/client/columnAndProduct',
        params: {
          columnCode: 'AppHomeCourseSlide',
          pageNum: 1,
          pageSize: 6
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          // res.data.map((item,index) => {
          //   if(item.columnCode == 'Heavy') {
          //     // 存储重磅课程列表
          //     this.HeavyProductList = res.data.splice(index,1)[0].productColumnRelations;
          //   }else if(item.columnCode == 'Free') {
          //     // 存储免费商品列表
          //     this.freeProductList = res.data.splice(index,1)[0].productColumnRelations;
          //   }
          // })
          // 存储原数组splice剩下的数据
          this.b_productList = res.data;
        }
      })
    },
    // 获取图书分类列表
    getBooksColumns() {
      this.$ajax({
        url: '/hxclass-pc/pc-mall/app/book-category'
      }).then(res=>{
        if(res.code == 200 && res.success) {
          res.data.map(async item=>{
            if(item.name == '精品图书') {
              this.Books_productList = item.children;
              this.Books_productList.map(async item => {
                let arr = await this.getProductListByCode(item.code);
                if(arr.records.length) {
                  // 改版数据存储
                  this.BooksproductList.push(...arr.records)

                  this.$set(item,'children',arr.records)
                }else {
                  this.$set(item,'children',[])
                }
              })
            }
            if(item.name == '相关教具') {
              this.training_productList = item.children;
              let arr = await this.getProductListByCode(item.code, 10);
              if(arr.records.length) {
                // 改版数据存储
                this.trainingProductList.push(...arr.records)

                this.training_Handle = arr.records[0]
                this.$set(this,'training_productList',arr.records)
              }else {
                this.$set(this,'training_productList',[])
              }
            }
          })
        }
      })
    },

    async getProductListByCode(e,size) {
      let res = await this.$ajax({
        url: '/hxclass-pc/pc-mall/pc/list',
        params: {
          categoryCode: e,
          pageNum: 1,
          pageSize: size || 3
        }
      })
      if(res.code == 200 && res.success) {
        return res.data
      }
    },

    // 获取资讯分类
    getInfotab() {
      this.$ajax({
        url: "/hxclass-pc/ArticleColumn/list/News",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.infotabList = res.data;
          this.infoTypeId = res.data[0].columnCode;
        }
      });
    },
    // 获取资讯列表
    getInfo() {
      this.$ajax({
        url: "/hxclass-pc/Article/client/list",
        params: {
          columnCode: this.infoTypeId,
          pageNum: 1,
          pageSize: 5,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.infoList = res.data.records;
        }
      });
    },

    // 获取商品分类接口
    getShopTypeList() {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/product-category",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arr = [];
          res.data.map((item) => {
            if (item.children && item.children.length) {
              item.children.map((ite, ind) => {
                if (ite.productList && ite.productList.length) {
                } else {
                  item.children.splice(ind, 1);
                }
              });
              arr.push(item);
            } else {
              arr.push(item);
            }
          });
          arr = arr.filter((item) => {
            return (
              (item.children && item.children.length) ||
              (item.productList && item.productList.length)
            );
          });
          this.shoptypeList = arr;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 获取正在学课程数据
    getLearningList() {
      this.$ajax({
        url: '/hxclass-pc/course/getUserCourseLearning',
        methods: 'get',
        params: {
          pageNum: 1,
          pageSize: 3
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.learningList = res.data.records;
        }
      })
    },

    // 获取学习地图数据
    getStudyMap() {
      this.$ajax({
        url: '/hxclass-pc/ProductColumnRelation/client/columnAndProduct',
        params: {
          columnCode: 'AppCourseMap',
          pageNum: 1,
          pageSize: 9999
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.studyMapList = res.data;
        }
      })
    },

    toGdz(item){
      let lastIndex = item.fileUrl.lastIndexOf('.');
      let suffix = item.fileUrl.substring(lastIndex+1)
      if(suffix.toLowerCase() == 'epub'){
        this.$router.push(
          "/ebookDetail?url=" +
            this.$AES.encode_data(String(item.fileUrl))
        );
      } else {
        this.$router.push(
          "/voiceDetail?autismId=" +
            this.$AES.encode_data(String(item.autismId)) +
            "&vtypeID=" +
            this.$AES.encode_data(String(this.vtypeID))
        );
      }
    },
    
    changeMaskv(e) {
      if (e == 1) {
        this.swiperLeftv = true;
      } else if (e == 2) {
        this.swiperLeftv = false;
      } else if (e == 3) {
        this.swiperRightv = true;
      } else if (e == 4) {
        this.swiperRightv = false;
      }
    },

    // 获取孤独症
    getVoicetab() {
      this.$ajax({
        url: "/hxclass-pc/autism/select/type",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(res.data.length){
            this.vtypeID = res.data[0].autismClassificationId;
            this.getIntroduce(this.vtypeID)
            this.getVoice(this.vtypeID)
          }
        }
      });
    },

    // 获取孤独症介绍
    getIntroduce(vtypeID) {
      this.$ajax({
        url: "/hxclass-pc/autism/introduce",
        params: {
          autismClassificationId: vtypeID,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.introduce = res.data.name;
        }
      });
    },
    
    // 获取孤独症文件列表
    getVoice(vtypeID) {
      this.$ajax({
        url: "/hxclass-pc/autism/list",
        params: {
          autismClassificationId: vtypeID,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.voiceList = res.data;
        }
      });
    },

    // 获取科普视频
    getClient(code,pageSize) {
      this.$ajax({
        url: "/hxclass-pc/Article/client/list",
        params: {
          columnCode: code,
          queryLevel: 1,
          sortType: 3,
          pageNum: 1,
          pageSize: pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (code == 'SPVideo'){
            this.SPVideoList = res.data.records
            this.SPVideoTotal = res.data.total
          }
        }
      });
    },

    // 获取资讯列表
    getInfoList(e) {
      this.$ajax({
        url: '/hxclass-pc/Article/client/list',
        params: {
          columnCode: 'QA',
          sortType: 3,
          pageNum: 1,
          pageSize: 3
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.bwbdInfolist = res.data.records
        }
      })
    },

    // 记录用户轨迹
    saveUserTrack(params) {
      userTrack(params)
    }
  },
  watch: {
    infoTypeId(newVal, oldVal) {
      this.getInfo();
    }
  }
}
</script>

<style scoped lang="less">
  .revision{
    padding-top: 80px;
  }
  .autism-item{
    margin: 47px auto 0;
    // margin-top: 56px;
    // height: 430px;
    padding: 30px;
    background-image: url(~@/assets/image/publicBenefit/bg_01.png);
    background-size: cover;
    background-repeat: no-repeat;
    .margin-area{
      width: 1200px;
      margin: 0 auto;
    }
    .public-h4{
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 36px;
    }
    .content{
      position: relative;
      margin-top: 50px;
      height: 274px;
      background: #FFFFFF;
      box-shadow: 2px 4px 10px 0px rgba(0,0,0,0.1);
      border-radius: 0px 19px 19px 0px;
      .side-left{
        width: 333px;
        height: 304px;
        background: #FFF8E6;
        position: absolute;
        left: 0;
        bottom: 0;
        box-shadow: 5px 0px 27px 0px rgba(0,0,0,0.1);
        padding: 12px 14px;
        .title{
          display: inline-block;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 29px;
          margin-bottom: 8px;
        }
        .detail{
          padding: 5px;
          // background: #fff;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 24px;
          text-indent: 2em;
        }
        &::after{
          content: '';
          position: absolute;
          right: -30px;
          top: 0;
          width: 0;
          height: 0;
          border-bottom: 30px solid #8ACDDC;
          border-right: 30px solid transparent;
        }
      }

      .swiper-right{
        position: relative;
        float: right;
        width: calc(100% - 350px);
        .tabSwiper{
          margin: 0 60px;
          .swiper-item{
            width: 134px;
            transition: all 0.4s ease;
            margin-top: 16px;
            cursor: pointer;
            .swiper-inner{
              .cover{
                border-radius: 10px 10px 10px 10px;
                transition: all 0.4s ease;
                width: 100%;
                height: 194px;
                background-repeat: no-repeat;
                object-fit: cover;
              }
              .title{
                margin-top: 10px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 21px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .swiper-item:hover{
            margin-top: 8px;
            .swiper-inner{
              .cover{
                box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
              }
              .title{
                color: @theme;
              }
            }
          }
        }
        .proright, .proleft{
          width: 28px;;
        }
        .voice-prev{
          position: absolute;
          top: 50%;
          left: 10px;
        }
        .voice-next{
          position: absolute;
          top: 50%;
          right: 15px;
        }
      }
      .clear{
        clear: both;
      }
    }
    .else_content{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .kpVideo{
        width: 860px;
        .video-list{
          overflow: hidden;
          padding-right: 4px;
          padding-bottom: 4px;
          display: flex;
          flex-wrap: wrap;
          transform-style: preserve-3d;
          .side-item{
            width: calc((100% - 30px) / 2);
            margin-right: 30px;
            margin-top: 30px;
            padding: 16px;
            background: #fff;
            // float: left;
            display: flex;
            transition: all 0.4s ease;
            cursor: pointer;
            position: relative;
            .cover{
              min-width: 113px;
              width: 113px;
              height: 73px;
              background-repeat: no-repeat;
              object-fit: cover;
              transition: transform 0.5s;
            }
            .info{
              margin-left: 10px;
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              overflow: hidden;
              .head{
                // display: flex;
                // justify-content: space-between;
                >p{
                  font-size: 14px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                  line-height: 21px;
                  display: inline-block;
                  white-space: nowrap; 
                  width: 100%; 
                  overflow: hidden;
                  text-overflow:ellipsis;
                }
                .label{
                  font-size: 12px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #15B7DD;
                  line-height: 18px;
                  height: 18px;
                  overflow: hidden;
                }
              }
              .detail{
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 21px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .side-item::after{
            content: '';
            width: 414px;
            height: 62px;
            background: rgba(21,183,221,0.23);
            position: absolute;
            right: -4px;
            bottom: -4px;
            z-index: -1;
          }
          .side-item:hover{
            box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
            // transform: translateY(-2px);
            .cover{
              transform: scale(1.05);
            }
          }
          .side-item:nth-child(2n){
            margin-right: 0;
          }
          .side-item:first-child,.side-item:nth-child(2){
            margin-top: 0;
          }
        }
      }
      .bwbdBox{
        width: 306px;
        display: flex;
        flex-direction: column;
        .infoList{
          flex: 1;
          padding: 16px;
          background: #FAFAFA;
          .info:hover{
            box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
            .name{
              color: @theme;
            }
          }
          .info{
            padding: 12px;
            background: #F4F4F4;
            margin-bottom: 16px;
            cursor: pointer;
            transition: all 0.4s ease;
            .name{
              font-size: 14px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 21px;
            }
            .overview{
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 18px;
              margin-top: 5px;
            }
          }
        }
      }
      .title{
        display: flex;
        align-items: center;
        padding-top: 30px;
        margin-bottom: 20px;
        position: relative;
        .mall{
          position: absolute;
          right: 0;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
        }
        .heavyIcon{
          width: 18px;
          height: 21px;
        }
        p{
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 36px;
          margin-left: 8px;
        }
      }
    }
  }
  .t_mess_box{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 249px;
    position: relative;
    .allType{
      width: 266px;
      padding-left: 16px;
      position: absolute;
      z-index: 100;
      left: 185px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      img{
        width: 17px;
      }
      .t{
        flex: 1;
        background-color: #fff;
        padding: 14px 16px;
        .title{
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
          margin-bottom: 2px;
          cursor: pointer;
        }
        .all_type{
          display: flex;
          flex-wrap: wrap;
          p{
            margin-top: 14px;
            margin-right: 11px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            cursor: pointer;
            position: relative;
          }
          p::after{
            content: '/';
            position: absolute;
            right: -8.5px;
            color: #999999;
          }
          p:last-child::after{
            content: '';
          }
          p:hover{
            color: #15B7DD;
            text-decoration:underline
          }
        }
      }
    }
    .p_type_box{
      width: 205px;
      height: 249px;
      background-color: #fff;
      margin-right: 16px;
      padding:  16px;
      overflow-y: auto;
      
      .type{
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        position: relative;
        
        .o_type{
          width: 56px;
          text-align: center;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
          cursor: pointer;
          white-space: nowrap;
          position: relative;
        }
        .o_type:hover{
          color: #15B7DD;
        }
        .o_type::after{
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #999999;
          position: absolute;
          right: -15px;
          top: 50%;
          transform: translateY(-30%);
        }
        .noChild::after{
          display: none;
        }
        .child_type{
          display: flex;
          flex: 1;
          margin-top: 3px;
          p{
            white-space: nowrap;
            margin-right: 11px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
            position: relative;
            cursor: pointer;
          }
          p:hover{
            color: #15B7DD;
            text-decoration:underline
          }
          p:nth-child(2){
            margin-right: 0;
            width: 48px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .mall::after{
            content: '/';
            position: absolute;
            right: -8.5px;
            color: #999999;
          }
        }
      }
      .type:hover{
        .allType{
          z-index: 100;
        }
      }
      .type:first-child{
        margin-top: 0;
      }
    }
    .banber_box{
      flex: 1;
    }
    .u_mess_box{
      width: 210px;
      margin-left: 16px;
      background-color: #fff;
      position: relative;
      .learntitle{
        display: flex;
        align-items: center;
        padding: 12px;
        img{
          width: 11px;
          height: 13px;
          margin-right: 3px;
        }
        p{
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
        }
      }
      .noLearning{
        width: 70px;
        display: block;
        margin: 0 auto;
      }
      .learning{
        padding: 0 5px 0 12px;
        margin-top: 10px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        line-height: 20px;
        position: relative;
        cursor: pointer;
      }
      .learning::before{
        content: '';
        width: 5px;
        height: 10px;
        background-image: url(~@/assets/image/RevisionHome/learnIcon.png);
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      // .learning:nth-child(2){
      //   margin-top: 0;
      // }
      .user{
        height: 50px;
        background: linear-gradient(135deg, #D0F6FF 0%, #FFF7D4 100%);
        display: flex;
        align-items: center;
        padding-left: 25px;
        padding-right: 25px;
        img{
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        p{
          margin-left: 18px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: bold;
          color: #000000;
          line-height: 21px;
        }
      }
      .studying{
        justify-content: center;
        img{
          width: 14px;
          height: 15px;
          border-radius: 0;
        }
        p{
          margin-left: 6px;
        }
      }
      .noLogin{
        width: 92px;
        margin: 30px 54px 0;
      }
      .btn{
        width: 167px;
        height: 36px;
        background: #15B7DD;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 36px;
        text-align: center;
        border-radius: 0;
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }

    .swiper-slide{
      .banner-img{
        animation: fadeInBiga 0.8s  linear forwards;
        -webkit-animation: fadeInBiga 0.8s  linear forwards;
      }
      @keyframes fadeInBiga{
        // 0% {
        //   opacity:1;
        // }
        // 100% {
        //   opacity: 0.5;
        // }
      }
    }
    .swiper-slide-active{
      .banner-img{
        width: 100%;
        animation: fadeInBig 2s  linear forwards;
        -webkit-animation: fadeInBig 2s  linear forwards;
      }
      @keyframes fadeInBig{
        // 0% {
        //   opacity: 0.5;
        //   transform: scale(1.2);
        // }
        // 100% {
        //   opacity: 1;
        //   transform: scale(1);
        // }
      }
    }
  }
  .t_mess_box_cover::before{
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    text-shadow: -1px 1px #888888; /* 白色阴影 */
    z-index: 10;
    animation: fadeInUp 3s cubic-bezier(0, 0, 0.79, 1.38) forwards;
    // animation-play-state: paused;
    transition: all 3s;
  }

  @keyframes fadeInUp {
    0% {
      width: 100%;
    }

    100% {
      width: 0%;
    }
  }
  .banner-box {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    position: relative;
    .swiper-container {
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
    .swiper-slide{
      width: 774px;
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
    .swiper-pagination{
      bottom: 29px;
      left: 40px;
      // transform: translateX(-50%);
      /deep/.swiper-pagination-bullet{
        width: 20px;
        height: 4px;
        margin: 0 4px;
        border-radius: 2px;
        background-color: #eeeeee;
      }
    }
    .swiper-pagination-custom {
      height: 34px;
      text-align: center !important; //这里设置分页器的位置 放在行的末尾
      /*自定义分页器的样式*/
      /deep/ .swiper-pagination-customs {
        width: 48px;
        height: 3px;
        background: rgba(255, 255, 255, 0.5);
        display: inline-block;
        border-radius: 5px;
        margin: 0 3px;
        outline: 0;
        box-sizing: border-box;
      }
      /deep/ .swiper-pagination-customs:last-child {
        margin-right: 16px;
      }
      /*自定义分页器激活时的样式表现*/
      /deep/ .swiper-pagination-customs-active {
        background: #ffffff;
      }
    }
  }
  .GoodsBooks{
    .title{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 20px;
      .heavyIcon{
        width: 18px;
        height: 21px;
      }
      p{
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        margin-left: 8px;
      }
    }
    .box_Back{
      width: 100%;
      background-image: url(~@/assets/image/RevisionHome/booksBack.png);
      background-size: 100% auto;
      .bookBanner{
        width: 1200px;
        margin: 0 auto;
        position: relative;
        .contentBox{
          border-top: 4px solid #ffffff;
          padding: 15px 20px;
          background-image: url(~@/assets/image/RevisionHome/back1.png);
          height: 440px;
          .pro_box{
            display: flex;
            padding: 20px;
            background-color: #fff;
            margin-top: 20px;
            cursor: pointer;
            .banner-img{
              width: 80px;
              height: 80px;
            }
            .r_mess{
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 24px;
              padding: 4px 0;
              .name{
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 21px;
              }
              .priceOption {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .price{
                  font-size: 16px;
                  font-family: DIN Alternate-Bold, DIN Alternate;
                  font-weight: bold;
                  color: #EC6C01;
                  line-height: 20px;
                  position: relative;
                  margin-left: 8px;
                }
                .price::before{
                  content: '￥';
                  font-size: 12px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #EC6C01;
                  position: absolute;
                  left: -10px;
                  top: 1px;
                }
                .joinCar{
                  width: 16px;
                  height: 16px;
                  cursor: pointer;
                }
              }
            }
          }
          .pro_box:first-child{
            margin-top: 0;
          }
        }
        .typeBox{
          display: flex;
          align-items: center;
          padding: 15px 20px;
          background-color: #FFF7EC;
          .banner-img{
            width: 19px;
            height: 19px;
            margin-right: 11px;
          }
          .typeText{
            flex: 1;
          }
          .btn{
            cursor: pointer;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8A8A8;
            line-height: 21px;
            .aIcon{
              margin-left: 4px;
            }
          }
        }
        .cccBox{
          background-image: url(~@/assets/image/RevisionHome/back2.png);
        }
        .typecolor{
          color: #FBB454 !important;
        }
        .Books_swiper-button-next,.Books_swiper-button-prev{
          width: 52px;
          height: 52px;
          color: rgba(0,0,0,0.1);
          background-color: #ffffff;
          border: 1px solid rgba(0,0,0,0.1);
          text-align: center;
          line-height: 52px;
          border-radius: 50%;
          cursor: pointer;
        }
        .Books_swiper-button-next:hover,.Books_swiper-button-prev:hover{
          color: #ffffff;
          background-color: rgba(0,0,0,0.1);
        }
        .Books_swiper-button-next{
          position: absolute;
          left: -69px;
          top: 50%;
        }
        .Books_swiper-button-prev{
          position: absolute;
          right: -69px;
          top: 50%;
        }
      }

      .title{
        margin-top: 86px;
        img{
          width: 22px;
        }
      }
      .handle{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        .picture{
          width: 299px;
          height: 395px;
          border-radius: 5px;
          box-shadow: 20px 20px 20px 0px rgba(0,0,0,0.1);
          background-color: #fff;
          .banner-img{
            width: 299px;
            height: 299px;
          }
          .colorBox{
            width: 299px;
            height: 46px;
            background: linear-gradient(135deg, #FFF3E4 0%, #FFDDB1 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              height: 21px;
            }
          }
        }
        .handleMess{
          flex: 1;
          .trainingBanner{
            width: 712px;
            height: 196px;
            margin: 0 auto;
            margin-top: 30px;
            margin-bottom: 44px;
            .swiper-slide{
              background-color: #fff;
              padding: 10px;
              cursor: pointer;
              .banner-img{
                width: 100px;
                height: 100px;
              }
              .name{
                height: 36px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                margin-top: 4px;
              }
              .priceOption {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .price{
                  font-size: 16px;
                  font-family: DIN Alternate-Bold, DIN Alternate;
                  font-weight: bold;
                  color: #EC6C01;
                  line-height: 20px;
                  position: relative;
                  margin-left: 8px;
                }
                .price::before{
                  content: '￥';
                  font-size: 12px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #EC6C01;
                  position: absolute;
                  left: -10px;
                  top: 1px;
                }
                .joinCar{
                  width: 16px;
                  height: 16px;
                  cursor: pointer;
                }
              }
            }
            .training_swiper-button-next,.training_swiper-button-prev{
              width: 40px;
              height: 40px;
              color: rgba(0,0,0,0.1);
              background-color: #ffffff;
              border: 1px solid rgba(0,0,0,0.1);
              text-align: center;
              line-height: 40px;
              border-radius: 50%;
              cursor: pointer;
            }
            .training_swiper-button-next:hover,.training_swiper-button-prev:hover{
              color: #ffffff;
              background-color: rgba(0,0,0,0.1);
            }
            .training_swiper-button-next{
              position: absolute;
              left: -69px;
              top: 39%;
            }
            .training_swiper-button-prev{
              position: absolute;
              right: -69px;
              top: 39%;
            }
          }
          .price{
            font-size: 28px;
            font-family: DIN Alternate-Bold, DIN Alternate;
            font-weight: bold;
            color: #EC6C01;
            line-height: 42px;
            position: relative;
            margin-left: 48px;
            margin-top: 10px;
          }
          .price::before{
            content: '￥';
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #EC6C01;
            position: absolute;
            left: -11px;
            top: 7px;
          }
          .mess{
            display: flex;
            align-items: center;
            padding: 37px 40px 0;
            .btn1,.btn2{
              width: 100px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              cursor: pointer;
              margin-left: 24px;
              font-size: 14px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
            .btn1{
              background-color: #15B7DD;
            }
            .btn2{
              color: #15B7DD;
              border: 1px solid #15B7DD;
              .aIcon{
                width: 8px;
              }
            }
            .name{
              flex: 1;
              font-size: 20px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
  .columnCourse{
    width: 1200px;
    height: 337px;
    padding: 24px 30px;
    margin: 0 auto;
    margin-top: 30px;
    background: #FFFFFF;
    .swiper-slide{
      position: relative;
      .btn{
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        cursor: pointer;
        width: 180px;
        height: 28px;
        line-height: 28px;
        border: 1px solid rgba(153, 153, 153, 1);
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        text-align: center;
        color: #333333;
        .aIcon{
          margin-left: 4px;
          width: 6px;
        }
      }
      .btn:hover{
        background-color: #15B7DD;
        border-color: #15B7DD;
        color: #ffffff;
      }
      .title{
        display: inline-flex;
        align-items: center;
        margin-bottom: 7px;
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 27px;
        position: relative;
        .columnIcon{
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }
      }
      .title::after{
        content: '';
        width: 420px;
        height: 1px;
        background: rgba(21, 183, 221, 0.30);
        position: absolute;
        bottom: 6px;
        right: -436px;
      }
      .list{
        height: 236px;
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background: #FFFFFF;
        .product:hover{
          .banner-img{
            transform: scale(1.05);
          }
          .detail{
            .name{
              color: @theme;
            }
          }
        }
        .product{
          cursor: pointer;
          display: flex;
          width: 254px;
          margin-top: 30px;
          transition: all 0.4s ease;
          .banner-img{
            width: 80px;
            height: 80px;
            transition: all 0.4s ease;
          }
          .index{
            width: 27px;
            height: 22px;
            margin-right: 6px;
          }
          .detail{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 3px;
            .name{
              font-size: 14px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 21px;
              transition: all 0.4s ease;
            }
            .tyle{
              font-size: 11px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
              transform: scale(0.9);
              white-space: nowrap;
              transform-origin: left;
            }
          }
        }
        .product:nth-child(n+3){
          margin-left: 27px;
        }
        .product:nth-child(2n+1){
          margin-top: 0;
        }
      }
    }
    .columnswiper-pagination{
      text-align: center;
      /deep/.swiper-pagination-bullet{
        width: 25px;
        height: 5px;
        border-radius: 0;
        margin: 46px 5px;
        background-color: rgb(137, 134, 134);
      }
      /deep/.swiper-pagination-bullet-active{
        background: #15B7DD;
      }
    }
    .column_swiper-button-next,.column_swiper-button-prev{
      width: 39px;
      height: 39px;
      box-sizing: content-box;
      color: rgba(0,0,0,0.1);
      background-color: #ffffff;
      border: 1px solid rgba(0,0,0,0.1);
      text-align: center;
      line-height: 39px;
      border-radius: 50%;
      cursor: pointer;
    }
    .column_swiper-button-next:hover,.column_swiper-button-prev:hover{
      color: #ffffff;
      background-color: rgba(0,0,0,0.1);
    }
    .column_swiper-button-next{
      position: absolute;
      left: -81px;
      top: 39%;
    }
    .column_swiper-button-prev{
      position: absolute;
      right: -81px;
      top: 39%;
    }
  }
  .studyMap{
    width: 1200px;
    margin: 0 auto;
    .title{
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 20px;
      .heavyIcon{
        width: 18px;
        height: 21px;
      }
      p{
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        margin-left: 8px;
      }
    }
    .banber_box{
      position: relative;
      .btn_back{
        position: absolute;
        top: -44px;
        right: 0;
        display: flex;
        align-items: center;
        .studyMapswiper-pagination{
          padding: 0 7px;
          height: 18px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          /deep/.swiper-pagination-current{
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #15B7DD;
            line-height: 18px;
          }
        }
        .studyMapswiper-button-next,
        .studyMapswiper-button-prev{
          cursor: pointer;
          color: #C4C4C4;
          font-size: 14px;
          position: unset;
        }
      }
    }
    .swiper-slide{
      padding: 20px 40px;
      background: #FFFFFF;
      // height: 100%;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      .RecommendedReason{
        display: flex;
        align-items: center;
        img{
          width: 13px;
          height: 14px;
          margin-right: 3px;
        }
        .reasonTitle{
          font-size: 11px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 17px;
        }
        .reasonText{
          flex: 1;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          margin-left: 12px;
        }
      }
      .productList{
        margin-top: 20px;
        display: flex;
        .product{
          margin-right: 65px;
          position: relative;
          .mess{
            background: #F4F4F4;
          }
          .productimg{
            width: 220px;
            height: 125px;
          }
          .name{
            height: 48px;
            margin-top: 4px;
            padding: 0px 12px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
          }
          .tyle{
            margin-top: 8px;
            padding: 0px 12px;
            font-size: 11px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
            padding-bottom: 13px;
          }
          .operate{
            height: 34px;
            margin-top: 4px;
            background: #FFFFFF;
            border: 1px solid rgba(0,0,0,0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img{
              width: 11px;
              height: 11px;
              margin-right: 3px;
            }
            .whiteshopCarIcon{
              display: none;
            }
            p{
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 18px;
            }
          }
          .operate:hover{
            background: #15B7DD;
            border-color: #15B7DD;
            p{
              color: #FFFFFF;
            }
            img{
              display: none;
            }
            .whiteshopCarIcon{
              display: block;
            }
          }
        }
        .product:first-child:after{
          content: '';
          position: absolute;
          right: -47.5px;
          top: 50%;
          transform: translate(-50%,-50%);
          width: 0;
          height: 0;
          border-left: 15px solid #DADADA;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
        }
      }
    }
  }
  .selfSwiper{
    padding-top: 10px;
  }
  .selfStyle{
    position: relative;
    cursor: pointer;
  }
  .selfStyle::before{
    content: '';
    width: 203px;
    height: 10px;
    background: #FFFFFF;
    position: absolute;
    left: 0;
    top: -10px;
  }
  .selfStyle::after{
    content: '';
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 0 solid transparent;
    border-top: 10px solid #f6f6fc;
    // background: red;
    position: absolute;
    left: 183px;
    top: -10px;
  }
  .heavyCourse{
    width: 1200px;
    margin: 0 auto;
    .title{
      display: flex;
      align-items: center;
      padding-top: 30px;
      margin-bottom: 20px;
      position: relative;
      .mall{
        position: absolute;
        right: 0;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
      }
      .heavyIcon{
        width: 18px;
        height: 21px;
      }
      p{
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        margin-left: 8px;
      }
    }
    .banner-box{
      position: relative;
      .btn_back{
        position: absolute;
        top: -55px;
        right: 0;
        width: 120px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 19px 19px 19px 19px;
      }
      .swiper-button-next,.swiper-button-prev{
        height: 34px;
        top: 0;
        margin-top: 0;

      }
      .swiper-button-next:after,.swiper-button-prev::after{
        font-size: 12px;
        color: #C4C4C4;
      }
      .swiper-button-next:hover:after,.swiper-button-prev:hover:after{
        color: #15B7DD;
      }
      .Heavyswiper-pagination{
        width: auto;
        height: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        /deep/.swiper-pagination-current{
          color: #15B7DD;
        }
      }
      .swiper-slide{
        background: #FFFFFF;
        display: flex;
        padding: 20px 20px 16px 16px;
        .banner-img{
          width: 265px;
          height: 152px;
          margin-right: 16px;
        }
        .p_messDetail{
          flex: 1;
          overflow: hidden;
          .name{
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
          }
          .tyle{
            display: inline-block;
            background: #F4F4F4;
            border-radius: 2px 2px 2px 2px;
            margin-top: 12px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
            padding: 2px 6px;
          }
          .detail{
            margin-top: 14px;
            height: 30px;
            font-size: 10px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 15px;
          }
          .p_btn{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-left: 8px;
            .price{
              font-size: 22px;
              font-family: DIN Alternate-Bold, DIN Alternate;
              font-weight: bold;
              color: #EC6C01;
              line-height: 33px;
              position: relative;
            }
            .price::before{
              content: '￥';
              font-size: 12px;
              position: absolute;
              left: -10px;
              top: 4px;
            }
            .button{
              border-radius: 0;
              height: 36px;
              line-height: 36px;
              margin-top: 16px;
            }
          }
        }
      }
    }
    .book_else{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .box{
        width: 585px;
        .shops{
          display: flex;
          flex-wrap: wrap;
          .product:hover{
            box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
            img{
              transform: scale(1.05);
            }
            .mess{
              .name{
                color: @theme;
              }
            }
          }
          .product{
            width: 280px;
            height: 112px;
            padding: 16px;
            background: #F4F4F4;
            display: flex;
            margin-bottom: 25px;
            transition: all 0.4s ease;
            cursor: pointer;
            img{
              width: 80px;
              height: 80px;
              transition: all 0.4s ease;
            }
            .mess{
              padding-top: 4px;
              padding-left: 16px;
              .name{
                height: 42px;
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 21px;
                transition: all 0.4s ease;
              }
              .price{
                font-size: 16px;
                font-family: DIN Alternate-Bold, DIN Alternate;
                color: #EC6C01;
                line-height: 20px;
                position: relative;
                margin-left: 8px;
                margin-top: 4px;
              }
              .price::before{
                content: '￥';
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #EC6C01;
                position: absolute;
                left: -10px;
                top: 1px;
              }
            }
          }
          .product:nth-child(2n){
            margin-left: 25px;
          }
        }
        .boxTitle{
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 20px;
          img{
            width: 18px;
            height: 18px;
          }
          p{
            margin-left: 6px;
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
          }
        }
        .boxTitle::after{
          content: '';
          width: 471px;
          height: 1px;
          background: #AEADAD;
          position: absolute;
          right: 0;
        }
      }
    }
    .free_product{
      display: flex;
      justify-content: space-between;
      .product:hover{
        margin-top: -8px;
        border-top: 4px solid #ADD8FF;
        .banner-img{
          box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
        }
        .name{
          color: @theme;
        }
      }
      .product{
        width: 180px;
        padding: 22px 20px;
        background-color: #fff;
        transition: all 0.4s ease;
        border-top: 4px solid #AAEFFF;
        cursor: pointer;
        .indexbox{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .index{
            font-size: 24px;
            font-family: DIN Alternate-Bold, DIN Alternate;
            font-weight: bold;
            color: rgba(21,183,221,0.5);
            line-height: 36px;
            position: relative;
          }
          .index::before{
            content: '';
            width: 12px;
            height: 1px;
            background: #15B7DD;
            position: absolute;
            left: 2px;
            bottom: -3px;
          }
          .aIcon{
            color: rgba(203, 203, 203, 1);
            font-size: 18px;
          }
        }
        .name{
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 18px;
          height: 36px;
        }
        .tyle{
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 15px;
          margin-top: 4px;
        }
        .banner-img{
          max-width: 140px;
          height: 80px;
          display: block;
          margin: 16px auto 0;
        }
      }
    }
  }

  .information{
    width: 1200px;
    margin: 0 auto;
    .btn2{
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      margin-left: 24px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .btn2{
      // color: #15B7DD;
      // border: 1px solid #15B7DD;
      .aIcon{
        width: 8px;
      }
    }
    .title{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 8px;
      .heavyIcon{
        width: 21px;
        height: 21px;
      }
      p{
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        margin-left: 8px;
      }
    }
    /deep/.ant-tabs-tabpane{
      display: flex;
      height: 390px;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-bottom: 50px;
      .news{
        width: 396px;
        height: 192px;
        background: #FFFFFF;
        padding: 14px 14px 20px;
        margin-right: 6px;
        position: relative;
        cursor: pointer;
        img{
          width: 100%;
          height: 178px;
          margin-bottom: 22px;
        }
        .content{
          display: flex;
          .index{
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #15B7DD;
            line-height: 27px;
            position: relative;
            margin-right: 7px;
          }
          .index::after{
            content: '···';
            line-height: 3px;
            font-size: 20px;
            color: #E6E6E6;
            position: absolute;
            top: 32px;
            left: 3px;
          }
          .mess{
            .name{
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 24px;
            }
            .summary{
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
              margin-top: 12px;
              -webkit-line-clamp: 3; //（最多显示 2 行）
            }
            .option{
              display: flex;
              justify-content: space-between;
              width: 339px;
              position: absolute;
              bottom: 20px;
              p{
                font-size: 10px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 15px;
              }
              p:last-child{
                width: 72px;
                text-align: end;
              }
            }
          }
        }
      }
      .news:nth-child(2n + 4){
        margin-right: 0;
      }
      .news:nth-child(n + 5){
        margin-top: 6px;
        position: relative;
        top: -198px;
      }
      .news:nth-child(2){
        height: 390px;
      }
      .news:hover{
        box-shadow: 2px 4px 10px 0px rgba(0,0,0,0.1);
        .name{
          color: @theme !important;
        }
      }
    }
  }

  .active-modal{
    
    /deep/ .ant-modal-footer{
      display: none;
    }
    /deep/ .ant-modal-close{
      color: #ffffff;
    }
    .modal-content{
      position: relative;
      .bg{
        width: 100%;
      }
      .gif{
        position: absolute;
        left: 200px;
        top: 130px;
        width: 100px;
      }
    }
  }
  .sutdent-active{
    /deep/ .ant-modal-content{
      background-color: #15B7DD;
    }
  }
  .teahcer-active{
    /deep/ .ant-modal-content{
      background-color: #EE6A43;
    }
  }

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
    border-radius:10px;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius:10px;
    background:rgba(144, 147, 153, 0.3);
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background:rgba(144, 147, 153, 0.3);
  }
  .whiteBox{
    background-color: #fff;
  }
</style>